import React from 'react';
import './PartnerCompanies.css'; // Make sure to link the correct CSS file




const logos = [
  { src: 'https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/partner-companies%2FHTA0oEr%20-%20Imgur.png?alt=media&token=1bc54e3b-cd7b-4411-a107-90cd5a1eabc4', link: 'https://beeaccess.com/' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/partner-companies%2FHz28o9A%20-%20Imgur.jpg?alt=media&token=301e3d80-1f49-46fc-bf39-7430afa76347', link: 'https://www.betamaxhoist.com/' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/partner-companies%2FapHppsm%20-%20Imgur.png?alt=media&token=83c9824d-85cf-4a25-84ad-81c3a2a746e6', link: 'https://www.bisoh.co.jp/en/' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/partner-companies%2FX0Bj4Ki%20-%20Imgur.png?alt=media&token=c8a5f797-b78e-431a-a374-f666fd90218d', link: 'https://www.tractel.com/us' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/partner-companies%2FUEUC3gE%20-%20Imgur.png?alt=media&token=7e7217b9-3d78-4bcf-a5ce-0b8f9296bc85', link: 'https://altrex.com/en' },
  { src: 'https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/partner-companies%2FYgdOW8Y%20-%20Imgur.jpg?alt=media&token=70ff6d5e-2393-470b-9b99-10468c38c7f3', link: '' },



  // ... add more logos as needed
];


const PartnerCompanies = () => {
  return (
    <div className="partner-companies">
      <div className="logo-slideshow">
        {logos.map((logo, index) => (
          <div key={index} className="logo-container-companies">
            <a href={logo.link} target="_blank" rel="noopener noreferrer">
              <img src={logo.src} alt={`Company logo ${index + 1}`} />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PartnerCompanies;