import React from 'react';

const HexagonRentalServices = () => {
    return (
        <div className="service-container">
            <img src="https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/services-img%2FRDhE3JV%20-%20Imgur.png?alt=media&token=9e043e00-6e2f-45ea-a821-5ce77567cdfb" alt="Rental Services" className="service-image" />
            <h1>Rental Services</h1>

            <div className="service-content">
                <p>Welcome to Hexagon Scaffolding's Rental Services. Our company has established itself as a leader in the scaffolding industry, providing top-notch services for various construction needs.</p>
                <p>Our rental services are designed to cater to both short-term and long-term projects. We offer a wide range of scaffolding equipment that meets industry standards and ensures the safety and efficiency of your construction work.</p>
            </div>
        </div>
    );
};

export default HexagonRentalServices;
