import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaChevronDown, FaMapMarkerAlt } from 'react-icons/fa';
import "./Header.css";
import logo from "../../Assets/logo.jpg";

function Header() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isNavFixed, setIsNavFixed] = useState(false);

  const navigate = useNavigate();

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);

    const navHeight = 80;
    if (position > navHeight && !isNavFixed) {
      setIsNavFixed(true);
    } else if (position <= navHeight && isNavFixed) {
      setIsNavFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isNavFixed]);

  const handleLocationClick = () => {
    window.open("https://www.google.com/maps?ll=40.716171,-74.199498&z=15&t=m&hl=en&gl=US&mapclient=embed&q=369+Jelliff+Ave+Newark,+NJ+07108", "_blank");
  };

  return (
    <div id="wrap">
      <div
        id="header"
        style={{ transform: `translateY(${scrollPosition / 2}px)` }}
      >
        <div className="logo-container">
          <img src={logo} alt="Logo" className="header-logo" />
        </div>

        <div className="contact-info">
          <div className="header-content">
          
            {/* <Slideshow /> */}
            <div className="location-container" onClick={handleLocationClick}>
              <span>Our Location</span>
              <FaMapMarkerAlt className="location-icon" />
            </div>
          </div>
        </div>
      </div>
      <div id="nav" className={isNavFixed ? "fixed" : ""}>
        <button className="nav-button" onClick={() => navigate("/")}>Home</button>
        <div className="nav-item">
          <button className="nav-button">
            Products <FaChevronDown className="dropdown-icon" />
          </button>
          <div className="dropdown-menu">
            <button onClick={() => navigate("/products")}>All Products</button>
          </div>
        </div>
        <div className="nav-item">
          <button className="nav-button">
            Services <FaChevronDown className="dropdown-icon" />
          </button>
          <div className="dropdown-menu">
            <button onClick={() => navigate("/services")}>All Services</button>
            <button onClick={() => navigate("/services/rental")}>Rental</button>
            <button onClick={() => navigate("/services/sales")}>Sales</button>
            <button onClick={() => navigate("/services/service")}>Service</button>
            <button onClick={() => navigate("/services/installation")}>Installation</button>
            <button onClick={() => navigate("/services/engineering")}>Engineering</button>
            <button onClick={() => navigate("/services/training")}>Training</button>
            <button onClick={() => navigate("/services/license")}>Licensing</button>
          </div>
        </div>
        <div className="nav-item">
          <button className="nav-button">
            About <FaChevronDown className="dropdown-icon" />
          </button>
          <div className="dropdown-menu">
            <button onClick={() => navigate("/about-us")}>About Us</button>
            <button onClick={() => navigate("/our-team")}>Our Team</button>
            <button onClick={() => navigate("/contact")}>Contact</button>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
