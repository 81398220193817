import React from 'react';

const HexagonRepairServices = () => {
    return (
        <div className="service-container">
            <h1>Repair Services</h1>
            <img src="https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/services-img%2FjSWBheW%20-%20Imgur.png?alt=media&token=d037ec96-ab21-404e-8094-b0a2f36cbbe7" alt="Repair Services" className="service-image" />
            <div className="service-content">
                <p>We provide prompt repair services to address any issues with your scaffolding equipment. Our maintenance team ensures that all equipment is restored to optimal condition, minimizing downtime and ensuring safety. Regular maintenance checks and emergency repairs are part of our commitment to keeping your operations running smoothly.</p>
            </div>
        </div>
    );
};

export default HexagonRepairServices;
