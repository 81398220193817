import React from 'react';

const HexagonSalesServices = () => {
    return (
        <div className="service-container">
            <img src="https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/services-img%2FrHTyeU9%20-%20Imgur.png?alt=media&token=8e9868b2-fcb7-41df-8bd7-7f11fc385418" alt="Sales Services" className="service-image" />
            <h1>Sales Services</h1>

            <div className="service-content">
                <p>Welcome to Hexagon Scaffolding's Sales Services. Our company is dedicated to providing high-quality scaffolding products for all your construction needs.</p>
                <p>We offer a wide range of scaffolding equipment for sale, ensuring that you have access to the best tools and materials for your projects. Our products are designed to be durable, safe, and efficient.</p>
            </div>
        </div>
    );
};

export default HexagonSalesServices;
