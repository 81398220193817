import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SecondComponent.css';

const cardData = [
    {
      category: "Rentals",
      text: "Explore the newest trends in property rentals.",
      image: "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/services-img%2FRDhE3JV%20-%20Imgur.png?alt=media&token=9e043e00-6e2f-45ea-a821-5ce77567cdfb"
    },
    {
        category: "Sales",
        text: "Get the latest trends and strategies in sales.",
        image: "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/services-img%2FrHTyeU9%20-%20Imgur.png?alt=media&token=8e9868b2-fcb7-41df-8bd7-7f11fc385418"
      },
      {
        category: "Service",
        text: "Top solutions for all kind of damaged equipment",
        image: "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/services-img%2FypbbYoq%20-%20Imgur.png?alt=media&token=916a3009-d294-4827-963b-7604b0b99e2c"
      },
      {
        category: "Installation",
        text: "Learn about innovative installation techniques and tools.",
        image: "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/services-img%2FypbbYosq%20-%20Imgur.png?alt=media&token=41d842a4-998d-4082-9fb6-cefbb8badea1"
      },
      {
        category: "Engineering",
        text: "Learn about innovative Engineering.",
        image: "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/services-img%2FHulyOFY%20-%20Imgur.png?alt=media&token=76c6308d-85b1-4273-b543-9ac1e7e75d05"
      },
    {
      category: "Licensing",
      text: "Stay informed about the latest licensing regulations and changes.",
      image: "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/services-img%2FwtdTDXh%20-%20Imgur.png?alt=media&token=1f002654-11d5-4de7-839d-85117c62f5f0"
    },
  
   

   
];

const SecondComponent = () => {
    const navigate = useNavigate();

    const handleLearnMore = (category) => {
        navigate(`/details/${category}`);
    };

    return (
        <div className="latest-news-container">
            <Title />
            <div className="card-list">
                {cardData.map((data, index) => (
                    <Card key={index} data={data} onLearnMore={() => handleLearnMore(data.category)} />
                ))}
            </div>
        </div>
    );
};

const Title = () => (
    <section className="app-title">
        <h1>Scaffolding Safety First.</h1>
    </section>
);

const Card = ({ data, onLearnMore }) => (
    <article className="card">
        <CardHeader image={data.image} category={data.category} />
        <CardBody text={data.text} onLearnMore={onLearnMore} />
    </article>
);

const CardHeader = ({ image, category }) => (
    <header style={{ backgroundImage: `url(${image})` }} className="card-header">
        <h4 className="card-header--title">{category}</h4>
    </header>
);


const CardBody = ({ text, onLearnMore }) => (
    <div className="card-body">
        <p>{text}</p>
        <button className="button-primary" onClick={onLearnMore}>Learn More</button>
    </div>
);

export default SecondComponent;
