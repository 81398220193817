import React from "react";
import Hexagon from "../landing_page/Hexagon/Hexagon";
import "./HexagonServices.css";

const hexagonTexts = [
  "Rental",
  "Sales",
  "Service",
  "Installation",
  "Engineering",
  "Training",
  "Repair",
  "License"
];

const serviceData = [
  {
    title: "Rental",
    text: "Offering flexible rental options, Hexagon Scaffolding caters to temporary and long-term needs with a vast inventory of scaffolding systems. Ideal for various project sizes, our rentals are cost-effective and reliable. We provide timely delivery and pick-up services, ensuring minimal disruption to your workflow.",
    image: "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/services-img%2FRDhE3JV%20-%20Imgur.png?alt=media&token=9e043e00-6e2f-45ea-a821-5ce77567cdfb"
  },
  {
    title: "Sales",
    text: "Specializing in the sale of high-quality suspended scaffolds, Hexagon Scaffolding offers a variety of options to fit any project scale. Our products promise durability and safety, enhancing your on-site operations. From individual components to complete systems, we provide equipment that meets industry standards.",
    image: "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/services-img%2FrHTyeU9%20-%20Imgur.png?alt=media&token=8e9868b2-fcb7-41df-8bd7-7f11fc385418"
  },
  {
    title: "Service",
    text: "At Hexagon Scaffolding, our comprehensive service solutions cover maintenance, support, and consultations to extend the lifespan of your equipment and ensure your projects proceed without interruptions. Our team is always ready to provide technical assistance and on-site support whenever needed.",
    image: "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/services-img%2FypbbYoq%20-%20Imgur.png?alt=media&token=916a3009-d294-4827-963b-7604b0b99e2c"
  },
  {
    title: "Installation",
    text: "Hexagon Scaffolding provides expert installation services ensuring safety and efficiency for every project. Our skilled team guarantees a reliable setup, tailored to meet your specific construction needs. We offer both temporary and permanent installation solutions, always adhering to the highest safety standards.",
    image: "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/services-img%2FypbbYosq%20-%20Imgur.png?alt=media&token=41d842a4-998d-4082-9fb6-cefbb8badea1"
  },
  {
    title: "Engineering",
    text: "Our engineering services include the design and analysis of scaffolding systems tailored to your project's specifications. Hexagon Scaffolding employs advanced engineering practices to ensure the structural integrity and safety of our scaffolding solutions. We collaborate with your team to create efficient and effective designs.",
    image: "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/services-img%2FHulyOFY%20-%20Imgur.png?alt=media&token=76c6308d-85b1-4273-b543-9ac1e7e75d05"
  },
  {
    title: "Training",
    text: "Hexagon Scaffolding offers comprehensive training programs for your workforce. Our training covers safe scaffold erection, usage, and dismantling procedures, ensuring your team is well-equipped to handle our systems. We also provide certification courses to meet industry requirements and enhance on-site safety.",
    image: "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/services-img%2Fdq0lXmv%20-%20Imgur.png?alt=media&token=6abff9a3-8fca-4d3e-8c0a-099bfa594cb8"
  },
  {
    title: "Repair",
    text: "We provide prompt repair services to address any issues with your scaffolding equipment. Our maintenance team ensures that all equipment is restored to optimal condition, minimizing downtime and ensuring safety. Regular maintenance checks and emergency repairs are part of our commitment to keeping your operations running smoothly.",
    image: "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/services-img%2FjSWBheW%20-%20Imgur.png?alt=media&token=d037ec96-ab21-404e-8094-b0a2f36cbbe7"
  },
  {
    title: "License",
    text: "We ensure all scaffolding projects comply with local and national safety regulations. Hexagon Scaffolding assists in acquiring the necessary licensing, providing peace of mind and legal security for your construction activities. Our team navigates the complex regulatory environment on your behalf, ensuring full compliance.",
    image: "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/services-img%2FwtdTDXh%20-%20Imgur.png?alt=media&token=1f002654-11d5-4de7-839d-85117c62f5f0"
  }
];

const HexagonServices = () => {
  return (
    <div className="hexagon-services">
      <ul className="hexagon-services-layout">
        <div className="hexagon-layout">
          {hexagonTexts.map((text, index) => (
            <Hexagon key={index} text={text} />
          ))}
          <Hexagon text="Services" isCenter />
        </div>
      </ul>
      <div className="services-list">
        {serviceData.map((service, index) => (
          <div key={index} className="service">
            <div className="service-text">
              <h2 className="service-title">{service.title}</h2>
              <p>{service.text}</p>
            </div>
            <img src={service.image} alt={service.title} className="service-image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default HexagonServices;
