import React from 'react';

const HexagonLicenseServices = () => {
    return (
        <div className="service-container">
            <h1>License Services</h1>
            <img src="https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/services-img%2FypbbYoq%20-%20Imgur.png?alt=media&token=916a3009-d294-4827-963b-7604b0b99e2c" alt="License Services" className="service-image" />
            <div className="service-content">
                <p>Welcome to Hexagon Scaffolding's License Services. We ensure all scaffolding projects comply with local and national safety regulations. Hexagon Scaffolding assists in acquiring the necessary licensing, providing peace of mind and legal security for your construction activities.</p>
                <p>Our team navigates the complex regulatory environment on your behalf, ensuring full compliance with all relevant regulations. We strive to make the licensing process as smooth and straightforward as possible, so you can focus on your construction projects without any legal concerns.</p>
            </div>
        </div>
    );
};

export default HexagonLicenseServices;
