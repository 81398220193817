import React from 'react';
import "./HexagonEngineeringServices.css"
const HexagonEngineeringServices = () => {
    return (
        <div className="service-container">
            <img src="https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/services-img%2FHulyOFY%20-%20Imgur.png?alt=media&token=76c6308d-85b1-4273-b543-9ac1e7e75d05" alt="Engineering Services" className="service-image" />
            <h1>Engineering Services</h1>

            <div className="service-content">
                <p>Welcome to Hexagon Scaffolding's Engineering Services. We provide tailored engineering solutions to meet the specific needs of your construction projects.</p>
                <p>Our engineering team employs advanced techniques to design and analyze scaffolding systems, ensuring their structural integrity and safety. We collaborate closely with your team to create efficient and effective scaffolding designs.</p>
            </div>
        </div>
    );
};

export default HexagonEngineeringServices;
