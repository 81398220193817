import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import LandingPage from "./pages/landing_page/LandignPage.jsx";
import Header from "./pages/Header/Header.jsx";
import Footer from "./pages/Footer/Footer.jsx";
import Products from "./pages/Products/Products.jsx";
import AboutUs from "./pages/AboutUs/AboutUs.jsx";
import HexagonServices from "./pages/HexagonServices/HexagonServices.jsx";
import DetailPageWithParams from "./pages/landing_page/SecondComponent/DetailPage/DetailPage.jsx";
import HexagonRentalServices from "./pages/HexagonServices/HexagonRentalServices/HexagonRentalServices.jsx";
import HexagonSalesServices from "./pages/HexagonServices/HexagonSalesServices/HexagonSalesServices.jsx";
import HexagonInstallationServices from "./pages/HexagonServices/HexagonInstallationServices/HexagonInstallationServices.jsx";
import HexagonEngineeringServices from "./pages/HexagonServices/HexagonEngineeringServices/HexagonEngineeringServices.jsx";
import HexagonTrainingServices from "./pages/HexagonServices/HexagonTrainingServices/HexagonTrainingServices.jsx";
import HexagonLicenseServices from "./pages/HexagonServices/HexagonLicenseServices/HexagonLicenseServices.jsx";
import HexagonRepairServices from "./pages/HexagonServices/HexagonRepairServices/HexagonRepairServices.jsx";
import HexagonTeam from "./pages/OurTeam/OurTeam.jsx";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/products" element={<Products />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/our-team" element={<HexagonTeam />} />

        <Route path="/services" element={<HexagonServices />} />
        <Route path="/details/:category" element={<DetailPageWithParams />} />
        <Route path="/services/rental" element={<HexagonRentalServices />} />
        <Route path="/services/sales" element={<HexagonSalesServices />} />
        <Route path="/services/installation" element={<HexagonInstallationServices />} />
        <Route path="/services/engineering" element={<HexagonEngineeringServices />} />
        <Route path="/services/training" element={<HexagonTrainingServices />} />
        <Route path="/services/license" element={<HexagonLicenseServices />} />
        <Route path="/services/service" element={<HexagonRepairServices />} />


      </Routes>
      <Footer />
    </div>
  );
}

export default App;


/*



Platforms:

1. Skyclimber Manual
2. Skyclimber Boiler Platform
3. Altrex, bee access manual
4. all altrex
5. Tractle 
6. All tractle
7. Winsafe mod
8. Winsafe all
9. Louisville platform (Rope Scaffold)



Outrigger:

Winsafe 1000 LBS series
Winsafe 1500 LBS series
Winsafe Down & Under System
Winsafe Extra outreach

Altrex Out rigger system

Skyclimber outrigger system

Spider Outrigger system

Tractle outrigger



Motors:

Tractle 1000, 1200, 1500 Tirak
Tractle 1000, LE model

BisoMac 1000 LBS Model 308 and 210

Skyclimber Compaq 1000, 1250 1500

Skyclimber LNX 1000

pOWER CLIMBER 1000 PC1

Spider New Yorker 1000

Biso air motors

Betamx Leo XXL VFD
Betamax Leo XXL
Betamax Leo XXL single phase

Betamax Gemini

Reman & Geroger Swing Hoist
R&GC 1000 and 2000 LBS material hoist

Accessories:

Betamax Buckets
Safety Equioment
Lifeline
Netting Rolls




*/