import React from 'react';

const HexagonInstallationServices = () => {
    return (
        <div className="service-container">
            <img src="https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/services-img%2FypbbYoq%20-%20Imgur.png?alt=media&token=916a3009-d294-4827-963b-7604b0b99e2c" alt="Installation Services" className="service-image" />
            <h1>Installation Services</h1>

            <div className="service-content">
                <p>Welcome to Hexagon Scaffolding's Installation Services. Our team provides expert installation services to ensure the safety and efficiency of your scaffolding setups.</p>
                <p>We handle both temporary and permanent installations, always adhering to the highest safety standards. Our skilled team ensures that your scaffolding is set up correctly and securely.</p>
            </div>
        </div>
    );
};

export default HexagonInstallationServices;
