import React from 'react';

const HexagonTrainingServices = () => {
    return (
        <div className="service-container">
            <img src="https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/services-img%2Fdq0lXmv%20-%20Imgur.png?alt=media&token=6abff9a3-8fca-4d3e-8c0a-099bfa594cb8" alt="Training Services" className="service-image" />
            <h1>Training Services</h1>

            <div className="service-content">
                <p>Welcome to Hexagon Scaffolding's Training Services. We offer comprehensive training programs to ensure your team is well-equipped to handle scaffolding equipment safely and efficiently.</p>
                <p>Our training covers scaffold erection, usage, and dismantling procedures, providing your team with the knowledge and skills needed to maintain a safe work environment. We also offer certification courses to meet industry standards.</p>
            </div>
        </div>
    );
};

export default HexagonTrainingServices;
