import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
    return (
        <div className="about-container">
            <header className="about-header">
                <h1>About Us</h1>
            </header>
            <section className="about-content">
                <div className="about-image">
                    <img src="https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/static%2FKWrxMRD%20-%20Imgur.png?alt=media&token=df5677c1-c2b4-4abd-b75e-ae2b03f6228b" alt="Hexagon Scaffolding Logo" />
                </div>
                <div className="about-text">
                    <h2>Quality Care and Service</h2>
                    <p>Hexagon Scaffolding, LLC. is a leading name in suspended scaffolding and hanging scaffolding in NYC.</p>
                    <p>With 5 years of experience, Hexagon Scaffolding has quickly established itself by providing superior products and services throughout the New York metropolitan area and the surrounding states.</p>
                    <p>Our services include <strong>Rental</strong>, <strong>Sales</strong>, <strong>Service</strong>, <strong>Installation</strong>, <strong>Engineering</strong>, and <strong>Training</strong>. We pride ourselves on offering comprehensive solutions to meet all your scaffolding needs.</p>
                    <p>We provide 24-hour emergency service and the ability to service not only our own equipment, but also most competitors' equipment. It's no wonder customers choose us for their hanging scaffolding needs and continue to come back time after time.</p>
                    <p>Our customers' commitment to us is combined with our own dedication to serve them when other scaffolding companies cannot.</p>
                    <p>If you have any questions about our company or would like to talk with someone about renting or purchasing hanging scaffolding, please don't hesitate to contact us.</p>
                    <p>See for yourself why customers choose us for all of their hanging scaffolding needs!</p>
                    <p>We invite you to tour our facility here in Long Island City and be amazed by how well we keep our rental fleet ready to go at a moment's notice. At Hexagon Scaffolding, we truly value you as our customer. This is why we STRIVE to treat everyone as family.</p>
                </div>
            </section>
            <section className="business-hours">
                <h2>Business Hours</h2>
                <div className="hours-table">
                    <div className="hours-column">
                        <h3>OFFICE</h3>
                        <p>SUMMER SCHEDULE</p>
                        <p>From April to September</p>
                        <ul>
                            <li>Monday: 6:00 AM - 11:30 AM</li>
                            <li>Tuesday: 6:00 AM - 11:30 AM</li>
                            <li>Wednesday: 6:00 AM - 11:30 AM</li>
                            <li>Thursday: 6:00 AM - 11:30 AM</li>
                            <li>Friday: 6:00 AM - 11:30 AM</li>
                            <li>Saturday: CLOSED</li>
                            <li>Sunday: CLOSED</li>
                        </ul>
                    </div>
                    <div className="hours-column">
                        <h3>SHOP</h3>
                        <p>WINTER SCHEDULE</p>
                        <p>Starts Monday after Thanksgiving</p>
                        <ul>
                            <li>Monday: 7:00 AM - 3:30 PM</li>
                            <li>Tuesday: 7:00 AM - 3:30 PM</li>
                            <li>Wednesday: 7:00 AM - 3:30 PM</li>
                            <li>Thursday: 7:00 AM - 3:30 PM</li>
                            <li>Friday: 7:00 AM - 3:30 PM</li>
                            <li>Saturday: CLOSED</li>
                            <li>Sunday: CLOSED</li>
                        </ul>
                    </div>
                </div>
                <p>We are open to serve you Monday through Friday from 6 AM to 4:30 PM, and we are even open on most Saturdays during the summer.</p>
                <p>Our shop is open for delivery, pick-ups, and rental returns during the above hours. Extended hours are even held at the discretion of Hexagon Scaffolding to help accommodate your needs during the busiest times of the year.</p>
                <p>During these extended hours, extra service charges and restrictions may apply for deliveries etc. We also offer 24-hour emergency service through our voicemail system.</p>
            </section>
        </div>
    );
};

export default AboutUs;
